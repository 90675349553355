exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-blog-index-br-jsx": () => import("./../../../src/pages/blog/index.br.jsx" /* webpackChunkName: "component---src-pages-blog-index-br-jsx" */),
  "component---src-pages-blog-index-es-jsx": () => import("./../../../src/pages/blog/index.es.jsx" /* webpackChunkName: "component---src-pages-blog-index-es-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-new-br-jsx": () => import("./../../../src/pages/blog/new.br.jsx" /* webpackChunkName: "component---src-pages-blog-new-br-jsx" */),
  "component---src-pages-blog-new-es-jsx": () => import("./../../../src/pages/blog/new.es.jsx" /* webpackChunkName: "component---src-pages-blog-new-es-jsx" */),
  "component---src-pages-blog-search-jsx": () => import("./../../../src/pages/blog/search.jsx" /* webpackChunkName: "component---src-pages-blog-search-jsx" */),
  "component---src-pages-builder-editing-jsx": () => import("./../../../src/pages/builder-editing.jsx" /* webpackChunkName: "component---src-pages-builder-editing-jsx" */),
  "component---src-pages-chat-en-vivo-es-jsx": () => import("./../../../src/pages/chat-en-vivo.es.jsx" /* webpackChunkName: "component---src-pages-chat-en-vivo-es-jsx" */),
  "component---src-pages-chat-para-site-br-jsx": () => import("./../../../src/pages/chat-para-site.br.jsx" /* webpackChunkName: "component---src-pages-chat-para-site-br-jsx" */),
  "component---src-pages-chatbot-br-jsx": () => import("./../../../src/pages/chatbot.br.jsx" /* webpackChunkName: "component---src-pages-chatbot-br-jsx" */),
  "component---src-pages-chatbot-es-jsx": () => import("./../../../src/pages/chatbot.es.jsx" /* webpackChunkName: "component---src-pages-chatbot-es-jsx" */),
  "component---src-pages-custom-chatbot-glossary-index-tsx": () => import("./../../../src/pages/custom-chatbot/glossary/index.tsx" /* webpackChunkName: "component---src-pages-custom-chatbot-glossary-index-tsx" */),
  "component---src-pages-custom-chatbot-index-jsx": () => import("./../../../src/pages/custom-chatbot/index.jsx" /* webpackChunkName: "component---src-pages-custom-chatbot-index-jsx" */),
  "component---src-pages-custom-chatbot-library-jsx": () => import("./../../../src/pages/custom-chatbot/library.jsx" /* webpackChunkName: "component---src-pages-custom-chatbot-library-jsx" */),
  "component---src-pages-custom-chatbot-pricing-jsx": () => import("./../../../src/pages/custom-chatbot/pricing.jsx" /* webpackChunkName: "component---src-pages-custom-chatbot-pricing-jsx" */),
  "component---src-pages-demo-new-index-tsx": () => import("./../../../src/pages/demo-new/index.tsx" /* webpackChunkName: "component---src-pages-demo-new-index-tsx" */),
  "component---src-pages-ecommerce-jsx": () => import("./../../../src/pages/ecommerce.jsx" /* webpackChunkName: "component---src-pages-ecommerce-jsx" */),
  "component---src-pages-edit-symbol-jsx": () => import("./../../../src/pages/edit-symbol.jsx" /* webpackChunkName: "component---src-pages-edit-symbol-jsx" */),
  "component---src-pages-edtech-components-carousel-tsx": () => import("./../../../src/pages/edtech/components/Carousel.tsx" /* webpackChunkName: "component---src-pages-edtech-components-carousel-tsx" */),
  "component---src-pages-edtech-index-tsx": () => import("./../../../src/pages/edtech/index.tsx" /* webpackChunkName: "component---src-pages-edtech-index-tsx" */),
  "component---src-pages-email-marketing-jsx": () => import("./../../../src/pages/email-marketing.jsx" /* webpackChunkName: "component---src-pages-email-marketing-jsx" */),
  "component---src-pages-growth-marketing-playbook-forms-form-growth-marketing-playbook-tsx": () => import("./../../../src/pages/growth-marketing-playbook/Forms/FormGrowthMarketingPlaybook.tsx" /* webpackChunkName: "component---src-pages-growth-marketing-playbook-forms-form-growth-marketing-playbook-tsx" */),
  "component---src-pages-growth-marketing-playbook-index-tsx": () => import("./../../../src/pages/growth-marketing-playbook/index.tsx" /* webpackChunkName: "component---src-pages-growth-marketing-playbook-index-tsx" */),
  "component---src-pages-index-br-jsx": () => import("./../../../src/pages/index.br.jsx" /* webpackChunkName: "component---src-pages-index-br-jsx" */),
  "component---src-pages-index-es-jsx": () => import("./../../../src/pages/index.es.jsx" /* webpackChunkName: "component---src-pages-index-es-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-knowledgebase-jsx": () => import("./../../../src/pages/knowledgebase.jsx" /* webpackChunkName: "component---src-pages-knowledgebase-jsx" */),
  "component---src-pages-library-conversion-rate-optimization-jsx": () => import("./../../../src/pages/library/conversion-rate-optimization.jsx" /* webpackChunkName: "component---src-pages-library-conversion-rate-optimization-jsx" */),
  "component---src-pages-library-customer-engagement-jsx": () => import("./../../../src/pages/library/customer-engagement.jsx" /* webpackChunkName: "component---src-pages-library-customer-engagement-jsx" */),
  "component---src-pages-library-customer-service-jsx": () => import("./../../../src/pages/library/customer-service.jsx" /* webpackChunkName: "component---src-pages-library-customer-service-jsx" */),
  "component---src-pages-library-index-jsx": () => import("./../../../src/pages/library/index.jsx" /* webpackChunkName: "component---src-pages-library-index-jsx" */),
  "component---src-pages-library-lead-generation-jsx": () => import("./../../../src/pages/library/lead-generation.jsx" /* webpackChunkName: "component---src-pages-library-lead-generation-jsx" */),
  "component---src-pages-live-chat-index-jsx": () => import("./../../../src/pages/live-chat/index.jsx" /* webpackChunkName: "component---src-pages-live-chat-index-jsx" */),
  "component---src-pages-live-chat-library-jsx": () => import("./../../../src/pages/live-chat/library.jsx" /* webpackChunkName: "component---src-pages-live-chat-library-jsx" */),
  "component---src-pages-main-caorusel-tsx": () => import("./../../../src/pages/main/Caorusel.tsx" /* webpackChunkName: "component---src-pages-main-caorusel-tsx" */),
  "component---src-pages-pop-up-br-jsx": () => import("./../../../src/pages/pop-up.br.jsx" /* webpackChunkName: "component---src-pages-pop-up-br-jsx" */),
  "component---src-pages-pop-up-es-jsx": () => import("./../../../src/pages/pop-up.es.jsx" /* webpackChunkName: "component---src-pages-pop-up-es-jsx" */),
  "component---src-pages-pop-ups-index-jsx": () => import("./../../../src/pages/pop-ups/index.jsx" /* webpackChunkName: "component---src-pages-pop-ups-index-jsx" */),
  "component---src-pages-precio-es-jsx": () => import("./../../../src/pages/precio.es.jsx" /* webpackChunkName: "component---src-pages-precio-es-jsx" */),
  "component---src-pages-precos-br-jsx": () => import("./../../../src/pages/precos.br.jsx" /* webpackChunkName: "component---src-pages-precos-br-jsx" */),
  "component---src-pages-pricing-full-jsx": () => import("./../../../src/pages/pricing/full.jsx" /* webpackChunkName: "component---src-pages-pricing-full-jsx" */),
  "component---src-pages-pricing-index-jsx": () => import("./../../../src/pages/pricing/index.jsx" /* webpackChunkName: "component---src-pages-pricing-index-jsx" */),
  "component---src-pages-ready-made-campaigns-jsx": () => import("./../../../src/pages/ready-made-campaigns.jsx" /* webpackChunkName: "component---src-pages-ready-made-campaigns-jsx" */),
  "component---src-pages-saas-jsx": () => import("./../../../src/pages/saas.jsx" /* webpackChunkName: "component---src-pages-saas-jsx" */),
  "component---src-pages-sdk-jsx": () => import("./../../../src/pages/sdk.jsx" /* webpackChunkName: "component---src-pages-sdk-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/blog-category.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-recent-jsx": () => import("./../../../src/templates/blog-recent.jsx" /* webpackChunkName: "component---src-templates-blog-recent-jsx" */),
  "component---src-templates-blog-tag-jsx": () => import("./../../../src/templates/blog-tag.jsx" /* webpackChunkName: "component---src-templates-blog-tag-jsx" */),
  "component---src-templates-chat-example-page-jsx": () => import("./../../../src/templates/chat-example-page.jsx" /* webpackChunkName: "component---src-templates-chat-example-page-jsx" */),
  "component---src-templates-glossary-page-tsx": () => import("./../../../src/templates/glossary-page.tsx" /* webpackChunkName: "component---src-templates-glossary-page-tsx" */),
  "component---src-templates-pmm-jsx": () => import("./../../../src/templates/pmm.jsx" /* webpackChunkName: "component---src-templates-pmm-jsx" */),
  "component---src-templates-tilda-page-jsx": () => import("./../../../src/templates/tilda-page.jsx" /* webpackChunkName: "component---src-templates-tilda-page-jsx" */)
}

