module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dashly","short_name":"Dashly","start_url":"/","background_color":"#ffffff","theme_color":"#FF7C16","icon":"src/images/d-favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"a630d0ff5346677908ea14af614d6d40"},
    },{
      plugin: require('../node_modules/gatsby-plugin-dashly/gatsby-browser.js'),
      options: {"plugins":[],"dashlyId":"4-d6a24bda4c9f625f882871078a5f01","mobileDelay":10000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
